.hero {
	height: 100vh;
	background-image: url('../../../Assets/furnicap_backdrop.webp');
	background-size: cover;
	background-position: center left;
}

@media only screen and (max-width: 575px) {
	.hero {
		height: 60vh;
	}
}
